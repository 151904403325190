<template>
  <div
    :class="{
      'nav-bar__filter': true,
    }"
    :aria-label="language.SHEIN_KEY_PWA_15044"
    role="tab"
    @click="onClick"
    @focus="$emit('navFocus', $event)"
  >
    <span
      :class="{
        'nav-bar__filter-label': true,
        'nav-bar__filter-label-highlight': filterNum,
      }"
    >
      {{ language.SHEIN_KEY_PWA_15044 }}
    </span>

    <!-- icon -->
    <span
      :style="{
        color: filterNum ? '#222' : '#666'
      }"
    >
      <Icon
        size="16px"
        name="sui_icon_filter_16px"
      />
    </span>

    <!-- filterNum -->
    <span
      v-if="filterNum"
      class="nav-bar__filter-filter-num"
    >
      {{ filterNum }}
    </span>

    <NavBarFilterDrawer 
      v-if="isLoadSlide"
      ref="refNavBarFilterDrawer"
      :visible.sync="visible"
      :menu-bar-config="menuConfig.menuBarConfig"
      :menu-items-config="menuConfig.menuItemsConfig"
      @sidePanelChange="onSidePanelChange"
      @resetAll="onResetAll"
      @closeSidePanel="close"
      @close="close"
      @clickDone="close"
    />
  </div>
</template>

<script setup>
import { unref, inject, defineAsyncComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

// hooks
import { useMenuConfig, useMenuFilterNum } from 'public/src/pages/components/FilterBar/NavBar/hooks/useMenuConfig.js'
import { useVisible, useIsLoadSlide } from 'public/src/pages/components/FilterBar/components/dropdown/hooks/index.js'

import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'
import { handlePricesParams } from 'public/src/pages/components/FilterBar/utils/prices.js'
import { handleCategoryParams } from 'public/src/pages/components/FilterBar/utils/category.js'
import { handleAttrParams } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { analysisClickFilterEntrance } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs, getSourceInsideFilterOrCloudTagPanel } from 'public/src/pages/components/FilterBar/utils/index.js'
const NavBarFilterDrawer = defineAsyncComponent(() => import('./NavBarFilterDrawer.vue'))

// inject
const locals = inject('locals', 0)
const sortMap = inject('sortMap', [])
const topTwoList = inject('topTwoList', [])
const catInfo = inject('catInfo', 0)
const language = inject('language', {})
const filterBar = inject('filterBar', {})
const queryParams = inject('queryParams', {})
const listAbtResult = inject('listAbtResult', {})
const fhContext = inject('fhContext', {})
const googleContext = inject('googleContext', {})
const autoStickyTime = inject('autoStickyTime') 
const isConfigAutoSticky = inject('isConfigAutoSticky') 

const { visible, toggle, close } = useVisible()
const isLoadSlide = useIsLoadSlide(visible)
const menuConfig = useMenuConfig({ locals, catInfo, filterBar, queryParams, language, listAbtResult })
const filterNum = useMenuFilterNum(menuConfig)
const emits = defineEmits(['change'])

const toStickyByClick = () => emits('toStickyByClick')
const onClick = () => {
  if (!isConfigAutoSticky.value) {
    analysisClickFilterEntrance(unrefs({ sortMap, topTwoList, queryParams }))
    toggle()
    return
  }

  toStickyByClick()
  const timer = setTimeout(() => {
    clearTimeout(timer)
    analysisClickFilterEntrance(unrefs({ sortMap, topTwoList, queryParams }))
    toggle()
  }, autoStickyTime.value)
}

const openSideFilter = () => {
  onClick()
}

// events
const onResetAll = () => {
  analysis.sideClickReset()

  emits('change', { 
    rightFilterFalg: true,
    params: { ...RESTET_QUERY_PARAMS }
  })
}

const onShopTypeChange = ({ data = {} }) => {
  const { value, mall_tag } = data
  const index = (filterBar.value?.filterData?.cloudTags || []).findIndex(tag => tag.value == value)
  // 埋点
  index > -1 && analysis.clickCloudTag({
    tagId: value,
    tagIndex: index + 1,
    facet: fhContext.value?.tag_id,
    gfilter: googleContext.value?.gfilter,
  })

  emits('change', {
    rightFilterFalg: true,
    params: { tag_ids: value, mall_tag, pic_cate_id: '', source: 'insideFilter', source_status: value ? 1 : 0 }
  })
}

const onCategoryChange = ({ data, cancel }) => {
  emits('change', {
    rightFilterFalg: true,
    params: handleCategoryParams({
      data,
      doAnalysis: true,
      facet: fhContext.value?.categories,
      gfilter: googleContext.value?.gfilter,
      isCancel: cancel,
      sourceFrom: 'sideFilter',
    })
  })
}

const onPriceChange = ({ minPrice, maxPrice, startMinPrice, startMaxPrice, priceInput }) => {
  emits('change', {
    rightFilterFalg: true,
    params: handlePricesParams({
      minPrice,
      maxPrice,
      priceInput,
      clickType: 'filter',
      startMinPrice,
      startMaxPrice,
      fhContext: unref(fhContext),
      googleContext: unref(googleContext),
      sourceFrom: 'sideFilter',
    })
  })
}

const onAttrChange = ({ data, operation, curSelectedItem, curSelectedNum, }) => {
  curSelectedNum && emits('saveCurrAttr', curSelectedItem?.id || curSelectedItem?.value, 'attr')

  // 处理自定义筛选中配置tsp
  if (curSelectedItem.nodeType == 7 && filterBar.value?.filterConfig?.isCccxFilterConfig) {
    const tspId = `${curSelectedItem.id}_${curSelectedItem.mix_value}`
    const list = menuConfig.value.menuItemsConfig
      .filter(item => item.type == 'tsp')
      .reduce((acc, cur) => {
        return acc.concat(cur.data?.data?.filter(obj => obj.active && tspId != obj.id) || [])
      }, [])
    
    list.forEach(obj => {
      const splits = obj.id.split('_')
      data.push({
        nodeType: 7,
        id: splits[0],
        mix_value: splits[1],
      })
    })
  }

  emits('change', {
    rightFilterFalg: true,
    params: handleAttrParams({
      data,
      operation,
      clickType: 'Filter',
      curSelectedItem,
      curSelectedNum,
      isCccxFilterConfig: filterBar.value?.filterConfig?.isCccxFilterConfig,
      sourceFrom: 'sideFilter',
    })
  })
}

const onTspChange = ({ tspData, operation, curSelectedItem, curSelectedNum }) => {
  const tspId = curSelectedNum ? curSelectedItem?.id?.split('_')?.[0] || '' : ''
  emits('saveCurrAttr', tspId, 'tsp')
  let tspIds = tspData.map(item => item.id)

  // 处理自定义筛选中配置tsp
  if (filterBar.value?.filterConfig?.isCccxFilterConfig) {
    const list = menuConfig.value.menuItemsConfig
      .filter(item => item.type == 'multi')
      .reduce((acc, item) => {
        const childList = item.data.data.reduce((acc2, cur2) => {
          return acc2.concat(cur2.data?.filter(obj => obj.active && obj.nodeType == 7 && !tspIds.includes(`${obj.id}_${obj.mix_value}`)) || [])
        }, [])

        return acc.concat(childList)
      }, [])
    tspIds = tspIds.concat(list.map(item => `${item.id}_${item.mix_value}`))
  }
  const tsp_ids = tspIds.join(',')
  operation != 'reset' && analysis.clickTsps({ tsps: tsp_ids })

  emits('change', {
    rightFilterFalg: true,
    params: {
      tsp_ids,
      ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom: 'sideFilter', active: curSelectedItem.active })
    }
  })
}

const onSidePanelChange = (params) => {
  if(params?.dataType == 'shopType') onShopTypeChange(params)
  if (params?.dataType == 'cate') onCategoryChange(params)
  if (params?.type === 'price') onPriceChange(params)
  if (['multi', 'img'].includes(params?.type)) onAttrChange(params)
  if (params?.type == 'tsp') onTspChange(params)
}

defineExpose({ openSideFilter })
</script>

<style scoped lang="less">
.nav-bar {
  &__filter {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  &__filter-label {
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #666;
  }

  &__filter-label-highlight {
    font-weight: 700;
    color: var(@sui_color_gray_dark1, #222);
  }

  &__filter-filter-num{
    position: absolute;
    top: 12px;
    right: 0;
    width: 13px;
    height: 13px;
    line-height: 13px;
    text-align: center;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    font-size: 12px;
    z-index: @zindex-hack;
    font-weight: normal;
  }
}
</style>
