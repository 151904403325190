<script>
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'
import TagAttr from './TagAttr/index.vue'
import TagCloudTag from'./TagCloudTag/index.vue'
import TagCategory from'./TagCategory/index.vue'
import TagDaily from'./TagDaily/index.vue'
import TagPicNavCate from'./TagPicNavCate/index.vue'
import TagPrices from'./TagPrices/index.vue'
import TagTsp from'./TagTsp/index.vue'

export default {
  name: 'TagItem',
  functional: true,
  render(h, { props, listeners }) {
    let tagComponent = TagAttr
    switch (props.item.tagItemType) {
      case TAG_ITEM_TYPE.ATTR:
        tagComponent = TagAttr
        break
      case TAG_ITEM_TYPE.CLOUD_TAG:
        tagComponent = TagCloudTag
        break
      case TAG_ITEM_TYPE.CATEGORY:
        tagComponent = TagCategory
        break
      case TAG_ITEM_TYPE.DAILY:
        tagComponent = TagDaily
        break
      case TAG_ITEM_TYPE.PIC_NAV_CATE:
        tagComponent = TagPicNavCate
        break
      case TAG_ITEM_TYPE.PRICES:
        tagComponent = TagPrices
        break
      case TAG_ITEM_TYPE.TSP:
        tagComponent = TagTsp
        break
      default:
        tagComponent = TagAttr
    }

    return h(tagComponent, { props, on: {
      toStickyByClick: listeners.toStickyByClick,
      beforeOpen: listeners.beforeOpen,
      change: listeners.change,
      saveCurrAttr: listeners.saveCurrAttr
    } })
  }
}

</script>
