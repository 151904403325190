<template>
  <div
    :class="{
      'ptn-blank__container': true,
      'ptn-blank__container-small': isSmallStatus,
    }"
  >
    <PicNavList
      :list="formatList"
      :space-between="8"
      :reverse="locals.GB_cssRight"
      :is-small-status="isSmallStatus"
      :is-config-active="false"
      :shape="PIC_TOP_NAV_SHAPE.CIRCLE"
      @change="onChange"
    />
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import PicNavList from '../PicNavList.vue'

import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import useToRealPage from 'public/src/pages/components/FilterBar/PicTopNav/utils/toRealPage.js'

const list = inject('list', {})
const locals = inject('locals', {})
// const language = inject('language', {})
const isSmallStatus = inject('isSmallStatus', false)

const formatList = computed(() => {
  const tabItem = list.value?.[0] || {}
  const { navs = [] } = tabItem
  
  return navs.map((navItem, navIndex) => ({
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    lazyImg: locals.value.LAZY_IMG,
    isLazyLoad: navIndex > 5,
    analysisData: getPicTopNavAnalysisData({ tabItem, navItem, navIndex })
  }))
})
const onToRealPage = useToRealPage({ list })
const onChange = navIndex => onToRealPage({ tabIndex: 0, navIndex })

</script>

<style lang="less" scoped>
.ptn-blank {
  &__container {
    padding: 12px;
    background: #fff;
    margin-bottom: -12px;
  }

  &__container-small {
    min-height: 1.17rem; // 设置同header高度，避免露出header底部分割线
    padding: 6px 12px;
    margin-bottom: -6px;
  }
}
</style>
