<template>
  <div 
    :class="{
      'ptn-tabs__container': true,
      'ptn-tabs__container-small': isSmallStatus,
    }"
  >
    <PicNavTabs 
      v-model="tabIndex"
      :tabs="formatTabs"
      :reverse="locals.GB_cssRight"
    />

    <SlideContent 
      v-model="tabIndex" 
      :list="list"
      :reverse="locals.GB_cssRight"
      :class="contentClass"
    >
      <SlideContentItem 
        v-for="(tab, index) in list" 
        :key="tab.tabId"
      >
        <PicNavList
          v-if="tabIndex === index || !firstRendering"
          ref="picNavListComp"
          :list="getFormatList(tab, index)"
          :space-between="8"
          :reverse="locals.GB_cssRight"
          :is-small-status="isSmallStatus"
          :active-value="activeValue"
          :shape="getShapeByTab(tab)"
          :first-rendering="firstRendering"
          @change="onChange" 
          @done="onDone"
        >
          <template 
            v-if="index < list.length - 1"  
            slot="right"
          >
            <SlideNext :label="language.SHEIN_KEY_PWA_21218" />
          </template>
        </PicNavList>
      </SlideContentItem>
    </SlideContent>

    <div :class="isSmallStatus ? 'split-line__small' : 'split-line'"></div>
  </div>
</template>

<script setup>
import { ref, watch, inject, computed } from 'vue'
import PicNavTabs from '../PicNavTabs/index.vue'
import PicNavList from '../PicNavList.vue'
import SlideNext from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavTabs/SlideNext.vue'
import SlideContent from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavTabs/SlideContent.vue'
import SlideContentItem from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavTabs/SlideContentItem.vue'
import { RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'

import { handlePicTopNavParams } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getTabAnalysisData, getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import useToRealPage from 'public/src/pages/components/FilterBar/PicTopNav/utils/toRealPage.js'


const list = inject('list', {})
const locals = inject('locals', {})
const queryParams = inject('queryParams', {})
const isSmallStatus = inject('isSmallStatus', false)
const language = inject('language', {})

const firstRendering = ref(true)
const activeValue = computed(() => queryParams.value?.pic_nav_id || '')
const tabIndex = ref(0)
const formatTabs = computed(() => {
  return list.value.map((item, index) => ({
    tabId: item.tabId,
    tabName: item.tabName,
    analyticData: getTabAnalysisData(item, index)
  }))

})
watch(list, () => {
  const { pic_tab_id } = queryParams.value
  const index = list.value.findIndex(tab => tab.tabId == pic_tab_id)
  tabIndex.value = index > -1 ? index : 0
}, { immediate: true })

const getFormatList = (tabItem, tabIndex) => {
  const { navs = [] } = tabItem || {}
  
  return navs.map((navItem, navIndex) => ({
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    lazyImg: locals.value.LAZY_IMG,
    isLazyLoad: tabIndex > 0 || navIndex > 5,
    analysisData: getPicTopNavAnalysisData({ tabItem, navItem, navIndex })
  }))
}

const contentClass = ref('')
watch(isSmallStatus, () => {
  contentClass.value = ''
  window.requestAnimationFrame(() => {
    contentClass.value = `picTopNav-container__animation`
  })
})

const picNavListComp = ref()
watch(tabIndex, () => {
  firstRendering.value = false
  picNavListComp.value?.forEach(vm => vm?.resetMoveX())
})

const onDone = (type) => {
  const maxLimit = list.value.length - 1 > tabIndex.value // 最后一页不翻页
  if (type === 'next' && maxLimit) {
    tabIndex.value += 1
  } else if (type === 'last' && tabIndex.value > 0) {
    tabIndex.value -= 1
  }
}

const getShapeByTab = tab => {
  return tab?.navs.every(nav => /^3000/.test(nav.nav_id))
    ? PIC_TOP_NAV_SHAPE.CIRCLE
    : PIC_TOP_NAV_SHAPE.RECT
}
const onToRealPage = useToRealPage({ list })

const emits = defineEmits(['change'])
const onChange = (navIndex, isSelect) => {
  firstRendering.value = false
  const isToRealPage = list.value[tabIndex.value]?.navs?.every(nav => /^3000/.test(nav.nav_id))
  if (isToRealPage) {
    onToRealPage({ tabIndex: tabIndex.value, navIndex })
    return
  }
  
  const params = handlePicTopNavParams(unrefs({ list, tabIndex, navIndex }))
  !isSelect && Object.keys(params).forEach(key => params[key] = '')
  emits('change', { params: {
    ...RESTET_QUERY_PARAMS, 
    ...params
  } })
}


</script>

<style lang="less" scoped>
.ptn-tabs {
  &__container {
    background: #fff;

    .split-line {
      height: 10px;
      margin-top: 12px;
      background: #F5F5F5;
    }

    .split-line__small {
      height: 12px;
      background: #fff;
    }
  }
}
</style>
