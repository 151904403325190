<script lang="jsx">
import ActiveIcon from 'public/src/pages/components/FilterBar/components/ActiveIcon.vue'

export default {
  name: 'TagButtonContainer',
  functional: true,
  inject: ['constantData'],
  render(_, { props, injections, slots, listeners }) {
    const { active, styles = {} } = props
    const { constantData = {} } = injections

    return (
      <div
        class="tag-cloud-tag__container"
        onClick={listeners.click}
      >
        <div
          class="tag-cloud-tag__content"
          style={styles}
        >
          {slots().default}
        </div>

        <ActiveIcon
          show={active}
          color={styles.color}
          cssRight={constantData.GB_cssRight}
        />
      </div>
    )
  }
}
</script>

<style lang="less">
.tag-cloud-tag {
  &__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    height: 39px;
    border: 1px solid rgba(255, 255, 255, 0);
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: auto;
    font-size: 12px;
    padding: 0 12px;
    white-space: nowrap;
    height: 27px;
  }
}
</style>
