import { formatMuitlData } from 'public/src/pages/components/filter_new/common/format'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

// 是否存在tsp数据
export function isExistTsp (filterBar) {
  return filterBar?.filterConfig?.exposureTsp
    && filterBar?.filterData?.filterTsps?.length
}

export function getActiveTspMap (queryParams) {
  const activeTspMap = {}
  const { tsp_ids } = queryParams
  const tspIds = tsp_ids && typeof tsp_ids === 'string' && tsp_ids.split(',')
  if (!Array.isArray(tspIds) || !tspIds.length) return {}

  tspIds.forEach(item => {
    const [id] = item.split('_')
    if (Array.isArray(activeTspMap[id])) {
      activeTspMap[id].push(item)
    } else {
      activeTspMap[id] = [item]
    }
  })

  return activeTspMap
}

export function formatTspList(filterTsp) {
  return formatMuitlData({
    tagGroupId: 'id',
    tagGroupName: 'name',
    tagList: 'data',
    dataItem: {
      tagId: 'id',
      tagName: 'label',
    }
  }, filterTsp)
}

export function getTspList ({ filterBar, queryParams }) {
  if (!isExistTsp(filterBar)) return []

  const list = formatTspList(filterBar?.filterData?.filterTsps)
  const activeTspMap = getActiveTspMap(queryParams)

  return list.map(item => {
    const selectedIds = activeTspMap[item.id] || []
    const selectedNum = selectedIds.length
    let label = selectedIds.length ? `${item.name}(${selectedNum})` : item.name

    if (selectedNum) {
      item.data.forEach(obj => {
        if (selectedIds.includes(obj.id)) {
          obj.active = true
        }
      })
    }

    return {
      ...item,
      label,
      active: selectedNum > 0,
      value: item.id,
      selectedIds,
      selectedNum,
      tagItemType: TAG_ITEM_TYPE.TSP
    }
  })
}
