import { mapState, mapGetters, mapMutations } from 'vuex'
import { storeModuleName as pl2ModuleName } from 'public/src/pages/product_app/store/modules/product_list_v2/index.js'
import { FILTER_BAR_MAP } from '../utils/const'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { emitCloseDropdown, emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll'

import FilterBar from 'public/src/pages/product_list_v2/components/FilterBar.vue'
import PicTopNav from 'public/src/pages/components/FilterBar/PicTopNav/index.vue'
import NavBar from 'public/src/pages/components/FilterBar/NavBar/index.vue'
import CloudTags from 'public/src/pages/components/FilterBar/CloudTags/index.vue'


import storeMixins from 'public/src/pages/product_list_v2/js/storeMixins.js'

const JOIN_LIST = [
  'page_real_class',
  'page_select_class',
  'page_search',
  'page_real_time_feedback',
  'page_picked',
  'page_goods_group',
]

export default ({
  storeModuleName = pl2ModuleName
} = {}) => ({
  components: {
    PicTopNav,
    NavBar,
    CloudTags,
    OneClickPayTag: () => import(/* webpackChunkName: "OneClickTag" */ 'public/src/pages/components/FilterBar/OneClickPayTag/index.vue'),
    FilterBar: storeMixins(FilterBar, {
      ...mapState(storeModuleName, ['ComponentState', 'PageState']),
      ...mapGetters(storeModuleName, [
        'locals',
        'language',
        'catInfo',
        'sum',
        'picTopNav',
        'sortMap',
        'sortAllList',
        'sortMap2',
        'fhContext',
        'googleContext',
        'listAbtResult',
      ]),
    }),
    PolicyBanner2: () => import(/* webpackChunkName: "plv2_policyBanner2" */ 'public/src/pages/product_list_v2/components/PolicyBanner/index.vue'),
  },
  data () { 
    return {
      // scrollEl: '', // container.vue中可以传入滚动元素，默认document
      constantData: {},
      FILTER_BAR_MAP,
    }
  },
  created () {
    // 兼容组件级别使用此方法(组件级别一般从客户端吊起，所以不考虑SSR情况)
    if(!Object.keys(this.locals)?.length && typeof window !== 'undefined') {
      this.resetLocals()
    }
    const {
      LAZY_IMG,
      IS_RW,
      GB_cssRight,
      is_include_critical_css,
      LAZY_IMG_SOLID_COLOR = ''
    } = typeof window === 'undefined' ? this.locals || {} : gbCommonInfo

    this.constantData = Object.assign({}, this.constantData, { LAZY_IMG_SOLID_COLOR, LAZY_IMG, IS_RW, GB_cssRight, is_include_critical_css })
  },
  computed: {
    filterData() {
      return this.ComponentState.FilterBar?.filterData || {}
    },
    filterBar() {
      return this.ComponentState?.FilterBar || {}
    },
    isReflesh() {
      return this.Request?.type === 'refresh'
    },
    queryParams() {
      return this.ComponentState?.queryParams || {}
    },
    closeBottomLeft () {
      return this.ComponentState?.ProductList?.config?.hideGoodsNum
    },
    isNewFilterBar () { 
      const { type, pageName } = this.catInfo
      if (this.apolloConfig?.[type] == 'off') return false
      if (JOIN_LIST.includes(pageName)) return true

      return false
    },
    ...mapGetters(storeModuleName, [
      'picTopNav',
      'sortMap',
      'sortMap2',
      'sum',
      'sortAllList',
      'apolloConfig',
      'fhContext',
      'googleContext',
    ]),
    isShowPicTopNav () {
      if (this.filterBar?.filterConfig?.hidePicTopNav) return false

      return true
    },
  },
  async beforeRouteLeave (to, from, next) {
    this.isNewFilterBar && emitCloseDropdown()
    next()
  },
  watch: {
    filterData: {
      handler ({ filterAttrs }) {
        const { min_price, max_price } = this.catInfo
        analysis.exposeFilter({ filterAttrs, filterPrices: { min_price, max_price } })
        analysis.exposeCategory()
      },
      immediate: true,
    },
    Request (newVal, oldVal) { 
      newVal.type !== oldVal.type && setDisabledScroll()
    },
    isReflesh () {
      setDisabledScroll()
    }
  },
  methods: {
    ...mapMutations(storeModuleName, [
      'resetLocals',
      'updateQueryParams'
    ]),
    saveCurrAttr (id, type = 'attr') { 
      this.PageState.staticAttrSetId = id
      this.PageState.staticFilterType = type 
    },
    toStickyByClick () {
      this.$refs.refPicTopNav?.toStickyByClick()
      this.$refs.refNavBar?.toStickyByClick()
      this.$refs.refCloudTags?.toStickyByClick()
    },
    toStickyAfterFilter () {
      this.$refs.refPicTopNav?.toStickyAfterFilter()
      this.$refs.refNavBar?.toStickyAfterFilter()
      this.$refs.refCloudTags?.toStickyAfterFilter()
      emitUpdateDropdownPanelHeight(300)
    },
    // 云标签变化
    onCloudTagsChange (params) {
      this.toStickyAfterFilter()
      this.updateQuery(params)
    },
    initFilterBarStyle (isFilter) { 
      this.isNewFilterBar ? this.initFilterBarStyleNew(isFilter) : this.initFilterBarStyleOld(isFilter)
    },
    initFilterBarStyleOld (isFilter) {
      this.$refs.FilterBar?.initFilterBarStyle(isFilter)
    },
    initFilterBarStyleNew (isFilter) { 
      if (!isFilter) {
        window.scrollTo(0, 0)

        return
      }
      if (!appEventCenter) return 
      
      // 避免goods 分块导致闪屏，设置列表高度，撑开页面
      $('.product-list-v2__list').css('min-height', '1000px') 

      appEventCenter.$once('goodsLoadedOnPageList', () => {
        $('.product-list-v2__list').css('min-height', null)
        this.toStickyAfterFilter()
      })
    },
    openSideFilter () {
      this.isNewFilterBar
        ? this.$refs.refNavBar?.openSideFilter()
        : this.$refs.FilterBar?.openSideFilter()
    },
    switchListPage () {
      emitCloseDropdown()
    }
  },
})
