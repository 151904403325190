<script>
import DropdownCategoryOld from './DropdownCategoryOld.vue'
import DropdownCategoryNew from './DropdownCategoryNew.vue'

export default {
  name: 'DropdownCategory',
  functional: true,
  render(h, { props, listeners }) {
    const comp = props.isNewCategory ? DropdownCategoryNew : DropdownCategoryOld
    return h(comp, { props, on: listeners })
  }
}
</script>
