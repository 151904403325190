
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'

export function getClickType ({ sort, sortMap, topTwoList }) {
  let clickType = 'sort'
  const sortMapIndex = sortMap.findIndex(item => item.value === sort)

  sortMapIndex < 0 && topTwoList.forEach((item, index) => {
    if ([item.value, item.ascValue, item.descValue].includes(sort)) {
      clickType = `top${index + 1}`
    }
  })

  return clickType
}

// 埋点-曝光
export const analysisExposeSort = ({ sort, sortMap, topTwoList, queryParams }) => {
  sort = sort !== undefined ? sort : Number(queryParams.sort || 0)
  analysis.exposeSort({
    sort,
    clickType: getClickType({ sort, sortMap, topTwoList }),
  })
}

// 点击sort，展开dropdownmenu
export const analysisClickSortEntrance = ({ sort, sortMap, topTwoList, queryParams }) => {
  analysisExposeSort({ sort, sortMap, topTwoList, queryParams })
  analysis.clickSortEntrance()
}

// 点击NavbarFilter，展开右侧边栏
export const analysisClickFilterEntrance = ({ sort, sortMap, topTwoList, queryParams }) => {
  analysisExposeSort({ sort, sortMap, topTwoList, queryParams })
  analysis.openSideFilter()
}

// 埋点-点击
export const analysisClickSort = ({ sort, sortType, clickType }) => {
  analysis.clickSort({
    sort,
    sortType,
    clickType,
  })
}

// 埋点 attrExport 下 topTwo点击
export const analysisClickTagItem = ({ item, queryParams, tagAllList }) => {
  const index = tagAllList.findIndex(tag => tag.value === item.value) + 1
  let filterType = item.value
  filterType === 'cate' && (filterType = 'category')

  analysis.clickTopTwoFilter({ index, filterType })
  analysis.exposeSort({ sort: queryParams.sort || 0, clickType: 'sort' })
}
