<template>
  <div
    ref="stickyEl"
    class="scroll-sticky"
    :class="className"
    :style="myStyles"
    :data-sticky-height="stickyHeight"
    :data-is-config-sticky="String(isConfigSticky)"
    :data-is-sticky-status="String(isStickyStatus)"
    :data-is-scroll-down="String(isScrollDown)"
    :data-sticky-top="top"
    :data-scroll-diff="stickyDiff"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted,  onBeforeUnmount } from 'vue'
import { useEventListener } from '../hooks/useEventListener'
// eslint-disable-next-line no-unused-vars
import { isDisabledScroll, setDisabledScroll, cancelDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'
import { emitUpdateDropdownPanelHeight } from '../eventCenter'
import { sleep, getIsLock } from 'public/src/pages/components/FilterBar/utils/index.js'

const emits = defineEmits(['stickyChange', 'smallChange'])
const props = defineProps({
  className: { type: String, default: '' },
  top: { type: Number, default: -1000000 },
  styles: { type: Object, default: () => ({}) }, 
  translageY: { type: Number, default: 0 }, // 下滑时的位移，露出header
  rootMarginTop: { type: Number, default: 0 }, // IntersectionObserver使用
  zIndex: { type: Number, default: 0 },
  stickyHeight: { type: Number, default: 0 }, // 吸顶后dom高度
  isConfigSticky: { type: Boolean, default: false }, // 是否配置了sticky,如abt配的标签吸顶，则不需要吸顶
  isStickyStatus: { type: Boolean, default: false }, // 当前是否吸顶状态
  isConfigSmall: { type: Boolean, default: false }, // PicTopNav有小图模式，传true，其他NavBar/Cloudtags为false
  isSmallStatus: { type: Boolean, default: false }, // 当前是否小图模式
  smallHeightDiff: { type: Number, default: 0 }, // PicTopNav使用图文下大小图模式下的高度差，计算滚动时候大小图切换临界点
  isScrollDown: { type: Boolean, default: false }, // 是否下滑
  loading: { type: Boolean, default: false },
  stickyDiff: { type: Number, default: 0 }, // 设置吸顶时候需要加上的滚动距离,如picks下，图文&CloudTags吸顶时，图文吸顶距离需要加上NavBar高度
  isTransform: { type: Boolean, default: true }, // 是否需要transform, 一键购引导弹窗不需要transform，避免fixed失效
})  

const {
  top,
  zIndex,
  loading,
  isConfigSmall,
  isConfigSticky,
  isStickyStatus,
  isSmallStatus,
  isScrollDown,
  translageY,
  stickyDiff,
} = toRefs(props)

const myStyles = computed(() => {
  return {
    ...props.styles,
    top: `${top.value + (isScrollDown.value ? translageY.value : 0)}px`,
    zIndex: zIndex.value,
    transform: props.isTransform ? `translate3d(0, 0, ${zIndex.value}px)` : null
  }

})

// sticky & scroll
const stickyEl = ref()
const lastScrollTop = ref(0)

// vdom [ios下getBoundingClientRect取值异常，是否吸顶改用vdom判断]
const vDom = ref()
const insertVDom = () => {
  const stickyDom = stickyEl.value
  const pDom = stickyDom.parentNode
  const dom = document.createElement('div')
  dom.classList.add('scroll-sticky__vdom')

  vDom.value = dom
  pDom.insertBefore(dom, stickyDom)
}
const removeVDom = () => vDom.value?.remove()

onMounted(() => {
  insertVDom()
})

onBeforeUnmount(() => removeVDom)

// scroll
useEventListener('scroll', async () => {
  const nowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
  const delta = nowScrollTop - lastScrollTop.value
  if (Math.abs(delta) < 1) return // 计算误差，如取消遮罩层时候的误差
  lastScrollTop.value = nowScrollTop
  const scrollDown = delta < 0
  if (scrollDown && !isBodyFixed && nowScrollTop < 1) {
    emits('update:isStickyStatus', false) // 当前滚动距离小于1时，设置为不吸顶
    isConfigSmall.value && emits('update:isSmallStatus', false)
    return
  }
  // isScrollDown.value !== scrollDown && cancelDisabledScroll() // 避免切换滚动方向时候，无法响应【上滑切换小图，马上下滑，可能存在无法恢复大图】

  if (
    getIsLock()
    || isDisabledScroll()
    || window.isBodyFixed
    || loading.value
    || !isConfigSticky.value
  ) return

  emits('update:isScrollDown', scrollDown)

  // 判断是否不吸顶
  const stickyDom = vDom.value
  const stickyRect = stickyDom?.getBoundingClientRect()
  if (nowScrollTop < 1) {
    emits('update:isStickyStatus', false) // 当前滚动距离小于1时，设置为不吸顶
    isConfigSmall.value && emits('update:isSmallStatus', false)
  } else  if (stickyRect.top - top.value < 1) {
    emits('update:isStickyStatus', true)
  } else if (stickyRect.top - (top.value + translageY.value) > 1) { 
    emits('update:isStickyStatus', false)
  }


  if (!isStickyStatus.value) {
    isConfigSmall.value && emits('update:isSmallStatus', false)
    return
  }

  // 不需要小图模式或者吸顶时候不需要走下面逻辑
  if (
    !isConfigSmall.value
    || (isStickyStatus.value && isSmallStatus.value)
  ) return

  let flag = false
  const nextElementSibling = stickyEl.value?.nextElementSibling
  // picks下图文&navbar/cloudtags都会吸顶
  if (nextElementSibling?.dataset?.isStickyStatus === 'true') {
    flag = true
  } else {
    const rect = stickyEl.value?.getBoundingClientRect()
    const nextRect = nextElementSibling.getBoundingClientRect()
    flag = (rect.top + rect.height) - nextRect.top >= 40
  }

  flag && setDisabledScroll()
  emits('update:isSmallStatus', flag)
})

const toSticky = async () => {
  const el = stickyEl.value
  const isLock = getIsLock()
  const maxScrollTop = document.body.scrollHeight - document.body.clientHeight // 最大滚动距离
  let computScrollTop = stickyDiff.value - top.value
  computScrollTop += isLock ? (-parseInt($('body').css('top'))) : (document.documentElement.scrollTop || document.body.scrollTop)
  let prev = el.previousElementSibling // 当前元素前一个dom

  if (prev) {
    const rect = el.getBoundingClientRect()
    const rectPrev = prev.getBoundingClientRect()
    const diff = rectPrev.bottom > rect.top ? (rectPrev.bottom - rect.top) : 0  // (rectPrev.bottom - rect.top)为两个元素之间的间距，如picks页面图文跟NavBar同时吸顶，间距为12px
    computScrollTop += rectPrev.top + rectPrev.height - diff
  } else {
    // 无当前元素上一个dom，需要找到父元素
    const parent = el.parentElement
    const rect = parent.getBoundingClientRect()
    computScrollTop += rect.top
  }
  const isUseComputedScrollTop = computScrollTop < maxScrollTop // 是否使用计算的滚动距离
  const scrollTop = Math.min(computScrollTop, maxScrollTop)

  setDisabledScroll() // 吸顶过程避免滚动
  emits('update:isSmallStatus', isUseComputedScrollTop) // 高度不足时，回复大图
  emits('update:isStickyStatus', isUseComputedScrollTop)
  emits('update:isScrollDown', false)
    
  if (isLock) {
    $('body').css({ 'top': -scrollTop }).attr('data-offset-top', scrollTop)
    emitUpdateDropdownPanelHeight(100)
    !isUseComputedScrollTop && appEventCenter?.$emit('resetHeaderAppStickyOffset') // 页面高度不足时，重置header fixed状态
    return
  }


  window.scrollTo(0, scrollTop - 1)
  emitUpdateDropdownPanelHeight(300)
  if (!isConfigSmall.value) return
  await sleep(300)
  if (getIsLock()) {
    $('body').css({ 'top': -scrollTop }).attr('data-offset-top', scrollTop)
  } else {
    window.scrollTo(0, scrollTop)
  }
  emits('update:isSmallStatus', isUseComputedScrollTop)
  emits('update:isStickyStatus', isUseComputedScrollTop)
  emits('update:isScrollDown', false)
  emitUpdateDropdownPanelHeight(300)
}

// 点击标签吸顶，外层调用
const toStickyByClick = () => {
  if (!isConfigSticky.value) return
  // 先注释下面2行，因为有了isStickyStatus.value的判断了
  // const rect = stickyEl.value?.getBoundingClientRect() 
  // if (rect.top - top.value < 1) return
  if (isStickyStatus.value) {
    emits('update:isSmallStatus', true)
    emits('update:isStickyStatus', true)
    emits('update:isScrollDown', false)
    emitUpdateDropdownPanelHeight(300)
    window?.appEventCenter?.$emit?.('changeHeaderSticky', true)
    return
  }
  toSticky()
  // 隐藏branch
  const timer = setTimeout(() => {
    clearTimeout(timer)
    window.vBus.$emit('setBranchVisible')
    window?.appEventCenter?.$emit?.('changeHeaderSticky', true)
  }, 200)
}

// 筛选后吸顶，外层调用
const toStickyAfterFilter = () => {
  if (!isConfigSticky.value || !isStickyStatus.value) return
  toSticky()   
}
const resetSticky = () => {
  isConfigSmall.value && emits('update:isSmallStatus', false)
  emits('update:isStickyStatus', false)
  emits('update:isScrollDown', false)
}

defineExpose({
  resetSticky,
  toStickyByClick,
  toStickyAfterFilter
})
</script>

<style lang="less" scoped>
.scroll-sticky {
  position: sticky;
  transition: all 0.3s;
}
</style>

