<script>
import DropdownCommon from './DropdownCommon.vue'
import MultiSelect from 'public/src/pages/components/filter_new/components/MultiSelect.vue'

export default {
  name: 'DropdownMultipleSelect',
  functional: true,
  render(h, { props, listeners }) {
    return h(DropdownCommon, {
      props,
      on: listeners
    }, [
      h(MultiSelect, {
        props
      })
    ])
  }
}
</script>
