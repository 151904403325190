<script>
import TagText from './TagText.vue'
import TagCccText from './TagCccText.vue'
import TagCccImage from './TagCccImage.vue'
import TagQs from './TagQs.vue'

export default {
  name: 'TagCloudTag',
  functional: true,
  render(h, { props, listeners }) {
    if (!props.item) {
      throw new Error('TagCloudTag: item is required')
    }
    const comp = (() => {
      if (props.item?.isNewQs) return TagQs
      if (!props.item?.cccConfig) return TagText
      if (props.item?.cccConfig?.displayType == 1) return TagCccImage
      return TagCccText
    })()
    return h(comp, { props, on: listeners })
  }
}
</script>
