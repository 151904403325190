
import { ref, watch } from 'vue'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

export function usePicNavCateTags ({ filterBar }) {
  const list = ref([])
  watch(filterBar, () => {
    list.value = !filterBar?.value?.filterData?.picTopNavCate?.length
      ? []
      : filterBar.value.filterData.picTopNavCate.map(item => ({
        value: item.cat_id,
        label: item.cat_name,
        tagItemType: TAG_ITEM_TYPE.PIC_NAV_CATE
      }))
  }, { immediate: true })

  return list
}
