import { ref, computed } from 'vue'
import { FILTER_BAR_MAP } from '../../utils/const'
const DEFAULT_COLOR = '#666' // 默认未选中下字体颜色
const DEFAULT_BACKGROUND_COLOR = '#F6F6F6'// 默认未选中下背景颜色

// 激活状态下背景色添加透明度
function addOpacity (color) {
  if (!color || color.startsWith('rgba')) return color

  let r, g, b

  if (color.startsWith('rgb')) {
    [r, g, b] = color.match(/\d+/g)
  } else {
    const hex = color.replace('#', '')

    if (hex.length === 3) {
      r = parseInt(hex.charAt(0).repeat(2), 16)
      g = parseInt(hex.charAt(1).repeat(2), 16)
      b = parseInt(hex.charAt(2).repeat(2), 16)
    } else {
      r = parseInt(hex.substring(0, 2), 16)
      g = parseInt(hex.substring(2, 4), 16)
      b = parseInt(hex.substring(4, 6), 16)
    }
  }

  return `rgba(${r}, ${g}, ${b}, 0.04)`
}

// 文本标签样式
export function useCccTextStyles ( refItem ) {
  return computed(() => { 
    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = refItem.value?.cccConfig?.displayContent || {}
      
    return refItem.value?.active ? {
      color: checkedBgColor,
      backgroundColor: addOpacity(checkedBgColor),
      border: `1px solid ${checkedBgColor }`,
    } : {
      color: DEFAULT_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })
}
 
// 图片标签样式
export function useCccImageStyles (refItem) {
  return computed(() => {
    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = refItem.value?.cccConfig?.displayContent || {}
    
    return refItem.value?.active ? {
      color: checkedBgColor,
      border: `1px solid ${checkedBgColor}`,
      backgroundColor: addOpacity(checkedBgColor),
    } : {
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })
}

// 图片样式
export function useCccImgData( cccConfig ) {
  return computed(() => {
    const { src, ratio } = cccConfig?.displayContent?.image || {}
    
    return {
      src,
      styles: {
        height: '12px',
        width: `${ratio * 12}px`,
      }
    }
  })
}

// 标签插坑样式
export function useTagSlotStyle (filterBarType, item) {
  const { cccConfig } = item.value
  const visible = ref(false)
  const visibleChange = flag => visible.value = flag

  const styles = computed(() => {
    if (filterBarType !== FILTER_BAR_MAP.CLOUD_TAGS || !cccConfig) return {}

    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = cccConfig?.displayContent || {}

    return (visible.value || item.value.selectedNum) ? {
      color: checkedBgColor,
      border: `1px solid ${checkedBgColor }`,
      backgroundColor: addOpacity(checkedBgColor),
    } : {
      color: DEFAULT_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })

  return {
    visibleChange,
    styles: styles,
    icon: cccConfig?.displayContent?.icon?.src || '',
  }
}
