/**
 * 筛选一些公共事件事件中心
 */
import { sleep } from 'public/src/pages/components/FilterBar/utils/index.js'

// #region 当前dropdown1展开时，点击dropdown2，需要关闭dropdown1
export const CLOSE_DROPDOWN = 'closeDropdown'
let dropdownInstance = null
export function getDropdownInstance () {
  return dropdownInstance
}
export function setDropdownInstance (instance) {
  dropdownInstance = instance
}
export function emitCloseDropdown () {
  window.appEventCenter.$emit(CLOSE_DROPDOWN)
}
export function onCloseDropdown (callback) {
  window.appEventCenter.$on(CLOSE_DROPDOWN, callback)
}
export function offCloseDropdown () {
  window.appEventCenter.$off(CLOSE_DROPDOWN)
}
// #endregion



// #region 更新panel高度
const UPDATE_DROPDOWN_PANEL_HEIGHT = 'updateDropdownPanelHeight'
export async function emitUpdateDropdownPanelHeight (loopTime = 0) {
  window.appEventCenter.$emit(UPDATE_DROPDOWN_PANEL_HEIGHT)
  if (loopTime === 0) return Promise.resolve()

  sleep(loopTime, () => window.appEventCenter.$emit(UPDATE_DROPDOWN_PANEL_HEIGHT))
}

export function onUpdateDropdownPanelHeight (callback) { 
  window.appEventCenter.$on(UPDATE_DROPDOWN_PANEL_HEIGHT, callback)
}
export function offUpdateDropdownPanelHeight () {
  window.appEventCenter.$off(UPDATE_DROPDOWN_PANEL_HEIGHT)
}

// #endregion

// #region CloudTag

// #endregion


