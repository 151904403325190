import { computed, } from 'vue'
import { PIC_TOP_NAV_LAYOUT } from 'public/src/pages/components/FilterBar/utils/const.js'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'

export function usePicTopNavLayout ({ catInfo, list }) {
  return computed(() => {
    if (catInfo.value.type === 'picks') return PIC_TOP_NAV_LAYOUT.PICKS
    if (list.value?.[0]?.is_blank) return PIC_TOP_NAV_LAYOUT.BLANK 
    if (list.value?.length > 1) return PIC_TOP_NAV_LAYOUT.TABS 
    
    return PIC_TOP_NAV_LAYOUT.LIST
  })
}

export function useClassName ({ prefix, shape, isSmallStatus, isActive, isConfigActive = true, extra = {} }) {
  return computed(() => {
    const isActiveStatus = isConfigActive.value
      ? (isActive?.value || false)
      : false
    const isCircle = shape.value === PIC_TOP_NAV_SHAPE.CIRCLE

    return {
      ...extra,
      [prefix]: true,
      [`${prefix}__small`]: isSmallStatus.value,
      [`${prefix}__active`]: isActiveStatus,
      [`${prefix}__rect`]: !isCircle && !isSmallStatus.value,
      [`${prefix}__rect-small`]: !isCircle && isSmallStatus.value,
      [`${prefix}__circle`]: isCircle && !isSmallStatus.value,
      [`${prefix}__circle-small`]: isCircle && isSmallStatus.value
    }
  })
}


