<template>
  <div 
    class="filter-ctn__range-change"
    :class="{
      'filter-ctn__range-change_bt': borderTop
    }"
  >
    <div class="range-change__content">
      <SliderRange
        ref="sliderRange"
        :prices-range="range"
        @priceChange="priceChange"
      />
    </div>
    <div class="range-change__operation">
      <BottomOperation
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="reset"
        @done="done"
      />
    </div>
  </div>
</template>

<script>
import BottomOperation from './UI/BottomOperation'
import SliderRange from './SliderRange'
import { emitEvent } from '../utils'

export default {
  name: 'RangeChange',
  components: {
    BottomOperation,
    SliderRange,
  },
  props: {
    sum: {
      type: Number,
      default: 0,
    },
    range: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    priceChange (payload) {
      emitEvent(this.$parent, 'rangeChange', { type: 'price', ...payload })
    },
    reset () { 
      this.$refs.sliderRange.reset()
    },
    done () {
      emitEvent(this.$parent, 'clickDone')
    },
  }
}
</script>

<style lang="less" scoped>
  .filter-ctn {
    &__range-change {
      padding: .32rem .32rem 0;
      background: #fff;
      &_bt {
        border-top: 1px solid #e5e5e5;
      }
      .range-change {
        &__content {
          .flexbox();
          flex-wrap: wrap;
          align-content: flex-start;
          // height: 2.86rem;
          min-height: 90px;
          max-height: 260px;
          overflow: visible;
          .font-dpr(24px);
        }

        &__operation {
          margin-top: 10px;
        }
      }
    }
  }
</style>
