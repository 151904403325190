
import { ref, computed, getCurrentInstance, nextTick } from 'vue'
import { getDropdownInstance, setDropdownInstance, onCloseDropdown, offCloseDropdown, emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { setDisabledScroll } from '../../../utils/disabledScroll'
/**
 * 面板按需加载
 * @param {*} refVisible 面板显示状态
 * @returns 
 */
export function useIsLoadSlide (refVisible) {
  const isLoaded = ref(false) // 是否已经加载过

  // 是否需要加载
  const isLoadSlide = computed(() => {
    if (isLoaded.value) return true

    if (refVisible.value) {
      isLoaded.value = true
      return true
    }

    return false
  } )

  return isLoadSlide
}

// 面板显示隐藏
export function useVisible() {
  const visible = ref(false)
  const instance = getCurrentInstance()
  const show = async () => {
    const oldInstance = getDropdownInstance()
    if (oldInstance === instance) return

    setDisabledScroll()
    emitCloseDropdown()
    setDropdownInstance(instance)
    onCloseDropdown(close)
    await nextTick() // 等待关闭事件执行完毕

    visible.value = true
  }
  const close = () => {
    setDisabledScroll()
    setDropdownInstance(null)
    offCloseDropdown()

    visible.value = false
  }

  const toggle = () => {
    visible.value ? close() : show()
  }

  return {
    visible,
    toggle,
    show,
    close,
  }
}
