<template>
  <div class="slide-content">
    <div 
      ref="slideInner" 
      class="slide-content-inner" 
      :style="innerStyle"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'

const props = defineProps({
  list: { type: Array, default: () => [] },
  value: { type: Number, default: 0 },
  reverse: { type: Boolean, default: false }
})

const moveX = ref(0)
const slideInner = ref()
const innerStyle = computed(() => ({ transform: `translateX(${moveX.value}px)` }))

const setMoveX = () => {
  const innerWidth = slideInner.value?.offsetWidth || 0
  const singleWidth = innerWidth / props.list.length
  const newMoveX = singleWidth * props.value
  moveX.value = props.reverse ? newMoveX : newMoveX * -1
}
watch(() => props.value, () => {
  setMoveX()
})

onMounted(() => {
  setMoveX()
})


</script>


<style lang="less" scoped>
.slide-content {
  width: 100%;
  display: flex;
  overflow: hidden;

  .slide-content-inner {
    display: flex;
  }
}
</style>
