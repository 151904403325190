<script lang="jsx">
import DropdownCommon from '../DropdownCommon.vue'
import SingleSelect from 'public/src/pages/components/filter_new/components/SingleSelect'

export default {
  name: 'DropdownCategoryOld',
  functional: true,
  render(_, { props, listeners }) {
    const { sum = 0, icon = '', label = '', active = false, selected = false, loading = false, closeBottomLeft = false, cate = {}, styles = {}, item = {}, language = {}, openDelay = 0 } = props
    return (
      <DropdownCommon
        label={label}
        icon={icon}
        loading={loading}
        selected={selected}
        open-delay={openDelay}
        styles={styles}
        on={listeners}
      >
        <SingleSelect
          border-top
          type="tree"
          data={item.data}
          title={language.SHEIN_KEY_PWA_14980}
          sum={sum}
          url-selected-id={item.selectedId}
          language={language}
          close-bottom-left={closeBottomLeft}
          loading={loading}
        />
      </DropdownCommon>
    )
  }
}
</script>
