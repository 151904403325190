
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { getSourceInsideFilterOrCloudTagPanel } from './index.js'

// 选中价格后，处理价格参数
export function handlePricesParams ({ minPrice, clickType, maxPrice, startMinPrice, startMaxPrice, priceInput, fhContext, googleContext, sourceFrom }) {
  let min_price = ''
  let max_price = ''
  if (minPrice !== startMinPrice || maxPrice !== startMaxPrice) {
    min_price = startMinPrice
    max_price = startMaxPrice
  }
  // 埋点
  analysis.dragPriceRange({
    startMinPrice,
    startMaxPrice,
    clickType,
    priceInput,
    facet: fhContext?.min_price,
    facet2: fhContext?.max_price,
    gfilter: googleContext?.gfilter,
  })

  return { 
    min_price, 
    max_price, 
    price_input: priceInput, 
    ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: 1 }) 
  }
}
