import { ref } from 'vue'
export function useStickyCommonConfig () {
  const isStickyStatus = ref(false)
  const isSmallStatus = ref(false)
  const isScrollDown = ref(false)

  return {
    isStickyStatus,
    isSmallStatus,
    isScrollDown
  }
}
