<script lang="jsx">
export default {
  name: 'DropdownLabel',
  functional: true,
  render(h, { props, listeners }) {
    return (
      <div
        class="dropdown-label__container"
        onClick={listeners.click}
      >
        <div
          class={{
            'dropdown-label__content': true,
            'dropdown-label__content-selected': props.selected,
            'dropdown-label__content-visible': props.visible
          }}
          style={props.styles}
        >
          <span class="dropdown-label__prefix-icon">
            {props.icon ? <img src={props.icon} /> : null}
          </span>

          <span class="dropdown-label__label">{props.label}</span>

          <i
            class={{
              sh_m_sui_icon_more_up_12px_2: props.visible,
              sh_m_sui_icon_more_down_12px_2: !props.visible
            }}
          ></i>
        </div>
      </div>
    )
  }
}
</script>

<style scoped lang="less">
.dropdown-label {
  &__container {
    display: inline-block;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__prefix-icon {
    img {
      width: 12px;
      height: 12px;
      display: flex;
      vertical-align: middle;
    }
  }
}

.sh_m_sui_icon_more_up_12px_2 {
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS44ODkxIDcuMTQ2NDFMNi4wMDAwMSAzLjI1NzMyTDIuMTEwOTIgNy4xNDY0MUwyLjgxODAzIDcuODUzNTJMNi4wMDAwMSA0LjY3MTk3TDkuMTgxOTkgNy44NTM1Mkw5Ljg4OTEgNy4xNDY0MVoiIGZpbGw9IiMxQzFDMUMiPgo8L3BhdGg+Cjwvc3ZnPg==)
    no-repeat;
  background-size: 100%;
  width: 12px;
  height: 12px;
}

.sh_m_sui_icon_more_down_12px_2 {
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS4xODE5NyA0LjE0NjQ4TDkuODg5MDggNC44NTM1OUw1Ljk5OTk5IDguNzQyNjhMMi4xMTA5IDQuODUzNTlMMi44MTgwMSA0LjE0NjQ4TDUuOTk5OTkgNy4zMjgwNEw5LjE4MTk3IDQuMTQ2NDhaIiBmaWxsPSIjMUMxQzFDIj4KPC9wYXRoPgo8L3N2Zz4=)
    no-repeat;
  background-size: 100%;
  width: 12px;
  height: 12px;
}
</style>
